import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useGetTestRunReportURLQuery,
	useLazyGetTraceURLQuery,
} from '@src/modules/test-runs/api';
import { testRunsRoutes } from '@src/modules/test-runs/routes/routes';
import { useSearchParams } from 'react-router-dom';

// @ts-ignore
import raw from './raw/index.txt';
import {
	TraceDownloadSuccessToastContent,
	TraceDownloadSuccessToastId,
} from '@src/common/components/TraceDownloadSuccessToastContent';
import { downloadFileByUrl } from '@src/common/helpers/files';
import { toast } from 'react-toastify';
import { ApplicationRoutes } from '@src/app/router/routes';
import Config from '@src/config';

declare global {
	interface Window {
		checksum_test_run_id?: string;
		playwrightReportBase64?: string;
	}
}

export const useTestReport = () => {
	const { id } = useParams<'id'>();
	const navigate = useNavigate();

	// in case arriving here without test run id, redirect to test runs page
	useEffect(() => {
		if (id) {
			window.checksum_test_run_id = id;
		}
		if (!id) {
			navigate(testRunsRoutes.testRuns.absolutePath);
		}
	}, [id, navigate]);

	useTrace(id);

	// get report url
	const {
		data: reportURL,
		isSuccess: reportUrlLoadedSuccessfully,
		isLoading: isLoadingReportURL,
	} = useGetTestRunReportURLQuery({ id }, { skip: !id });

	const [isReportZipSet, setIsReportZipSet] = useState<boolean>(false);
	const [isReportZipSetMountingLoading, setIsReportZipSetMountingLoading] =
		useState(false);

	useEffect(() => {
		if (!reportUrlLoadedSuccessfully) {
			return;
		}
		// if report url is available, fetch the html, extract the base64 encoded zip and set it to expected window field
		if (reportURL) {
			fetch(reportURL)
				.then((response) => {
					return response.text();
				})
				.then((html) => {
					const regex = /window.playwrightReportBase64 = "(.*)";/;
					const match = html.match(regex);
					if (match) {
						window.playwrightReportBase64 = match[1];
						setIsReportZipSet(true);
					}
				});
		}
		// in case test runs fetch complete but no report url, redirect to test runs page
		else {
			navigate(testRunsRoutes.testRuns.absolutePath);
		}
	}, [id, reportUrlLoadedSuccessfully, navigate, reportURL]);

	const divRef = useRef();
	useEffect(() => {
		if (!isReportZipSet) {
			return;
		}

		setIsReportZipSetMountingLoading(true);

		fetch(raw)
			.then((r) => r.text())
			.then((text) => {
				const fragment = document.createRange().createContextualFragment(text);
				if (divRef.current) {
					// @ts-ignore
					divRef.current.append(fragment);
				}
			})
			.finally(() => {
				setIsReportZipSetMountingLoading(false);
			});
	}, [isReportZipSet]);

	return {
		divRef,
		isLoading:
			isLoadingReportURL || !isReportZipSet || isReportZipSetMountingLoading,
	};
};

const useTrace = (id) => {
	const [queryParams, setQueryParams] = useSearchParams();
	const trace = queryParams.get('trace');
	const isViewMode = queryParams.get('view') === 'true';

	// this currently downloads the trace file and then instructs the user
	// to open the trace viewer manually
	const [getTraceSignedURL] = useLazyGetTraceURLQuery();
	useEffect(() => {
		if (!id || !trace) {
			return;
		}
		// first, delete from query params to avoid downloading again on refresh
		queryParams.delete('trace');
		queryParams.delete('view');
		setQueryParams(queryParams);
		// next, get the signed url and download the file
		(async () => {
			const response = await getTraceSignedURL({ id, testId: trace });

			// in view mode, open the trace viewer directly
			if (isViewMode) {
				window.location.href = `${
					Config.appUrl
				}/#${ApplicationRoutes.traceViewer.make()}?url=${encodeURIComponent(
					response.data
				)}`;
				return;
			}

			await downloadFileByUrl(response.data, 'trace.zip');
			toast.success(TraceDownloadSuccessToastContent, {
				toastId: TraceDownloadSuccessToastId,
				autoClose: false,
				closeOnClick: false,
			});
		})();
	}, [id, trace, getTraceSignedURL, queryParams, setQueryParams, isViewMode]);
};
